import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react'
import { StripeProvider } from 'react-stripe-elements';
import { ToastContainer, Zoom, toast } from 'react-toastify';

import { ReactComponent as Logo } from 'images/logo.svg';

import ViewAccount from 'components/view-account/view-account.component';
import ViewActivated from 'components/view-activated/view-activated.component';
import ViewFailedActivation from 'components/view-failed-activation/view-failed-activation.component';
import ViewPrivacy from 'components/view-privacy/view-privacy.component';
import ViewSuccessfulActivation from 'components/view-successful-activation/view-successful-activation.component';
import ViewTerms from 'components/view-terms/view-terms.component';
import WrapperForView from 'components/wrapper-for-view/wrapper-for-view.component';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';


import styles from './App.module.scss';
import 'styles/toasts.scss';

// Import store(s)
import accountStore from 'data/account-store'

// Feed store(s) in to wrapping provider
const stores = {
  accountStore
}

// Toastify requires you pass closeToast as the click handler
const CloseButton = ({ closeToast }) => (
  <CloseIcon style={{ width: 12, height: 12 }} onClick={closeToast} />
);

class App extends Component {
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Provider {...stores}>
          <Router>
            <div className={styles.app}>
              <div className={styles.logo}>
                <Logo />
              </div>
              <Switch>

                {/* 'WrapperForView' provides a consistent visual frame in addition to data 
                for the respective account id.  Fetched data is passed into the underlying
                Route component as a wrapping HOC */}

                {/* Displays current account id's information */}
                <Route
                  path="/id/:id"
                  render={() => 
                    <WrapperForView>
                      <ViewAccount />
                    </WrapperForView>
                  } />

                {/* Successfully activated with payment method */}
                <Route
                  path="/success/:id"
                  render={() => 
                    <WrapperForView>
                      <ViewSuccessfulActivation />
                    </WrapperForView>
                  } />

                {/* Acivation has failed to charge payment method */}
                <Route
                  path="/failure/:id"
                  render={() => 
                    <WrapperForView>
                      <ViewFailedActivation />
                    </WrapperForView>
                  } />

                {/* This device is already activated */}
                <Route
                  path="/activated/:id"
                  render={() => 
                    <WrapperForView>
                      <ViewActivated />
                    </WrapperForView>
                  } />

                {/* Terms of service */}
                <Route
                  path="/terms"
                  render={() => 
                    <WrapperForView>
                      <ViewTerms />
                    </WrapperForView>
                  } />

                {/* Privacy policy */}
                <Route
                  path="/privacy"
                  render={() => 
                    <WrapperForView>
                      <ViewPrivacy />
                    </WrapperForView>
                  } />
                  
                {/* Catch 404s */}
                <Route
                  path="/"
                  render={props => <h3>Thank you for choosing RainedOut.</h3>} />
              </Switch>
              <ToastContainer
                position={toast.POSITION.TOP_CENTER}
                transition={Zoom}
                autoClose={8000}
                closeButton={<CloseButton />}
              />
            </div>
          </Router>
        </Provider>
      </StripeProvider>
    );
  }
}

export default App;
