import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import { inject, observer } from 'mobx-react'
import { toast } from 'react-toastify';

import InvoiceItems from 'components/invoice-items/invoice-items.component';
import PaymentRequestForm from 'components/payment-request-form/payment-request-form.component';

import styles from './view-account.module.scss';

class ViewAccount extends Component {
  render() {
    const { name, plan, id } = this.props.accountStore;
    return (
      <React.Fragment>
        <div className={styles.account}>{name}</div>
        <div className={styles.intro}>
          <p>Welcome to RainedOut!</p>
          <p>Get connected with fast, easy messaging. We don’t sell customer data and instead need to charge a small fee for our service.</p>
          <p>Choose an option below to continue receiving messages:</p>
          <ul>
            <li>Option 1 - One subscription paid annually gets you unlimited text messaging from unlimited RainedOut accounts. Text is $7.30 per year and is paid below.</li>
            <li>Option 2 - If you prefer email messages, please <a className={styles.link} href="https://www.rainedout.com/faq" target="_new">go here</a> to remove your mobile number and add your email address.  Email is free.</li>
          </ul>
          <p>Thank you – we really appreciate your support.</p>
          <p>Happy Messaging!</p>
        </div>
        <InvoiceItems items={[plan]} />
        <div className={styles.paymentRequestForm}>
          <Elements>
            <PaymentRequestForm
              id={id}
              onSuccess={() => this.props.history.push(`/success/${id}`)}
              onError={() => toast.error('There was an error processing your payment. Please verify your card information.')}
              paymentLabel={plan.statement_descriptor}
              paymentAmount={plan.amount}
            />
          </Elements>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(inject('accountStore')(observer(ViewAccount)));
