import { decorate, observable, action } from "mobx"

class AccountStore {

  id = 'abc123';
  active = false;
  loading = true;
  name = "Account Name";
  email = "jane.doe@example.com";
  plan = {};
  isStripeCustomer = false;

  // Set account info
  setId = value => this.id = value;
  setActive = value => this.active = value;
  setLoading = value => this.loading = value;
  setName = value => this.name = value;
  setEmail = value => this.email = value;
  setPlan = value => this.plan = value;
  setIsStripeCustomer = value => this.isStripeCustomer = value;
}

decorate(AccountStore, {
  setId: action,
  setActive: action,
  setLoading: action,
  setName: action,
  setEmail: action,
  setPlan: action,
  setIsStripeCustomer: action,
  id: observable,
  active: observable,
  loading: observable,
  name: observable,
  email: observable,
  plan: observable,
  isStripeCustomer: observable
})

export default new AccountStore();
