import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { DoubleBounce } from 'better-react-spinkit'

import styles from './loader.module.scss'

class Loader extends Component {

  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
  }

  static defaultProps = {
    color: 'currentColor',
    size: 24
  }

  render() {
    return (
      <div className={styles.loader}>
        <DoubleBounce color={this.props.color} size={this.props.size} />
      </div>
    );
  }
}

export default Loader;