import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { usd } from 'common/formats';

import styles from './invoice-item.module.scss';

class InvoiceItem extends Component {

  static propTypes = {
    amount: PropTypes.number,
    interval: PropTypes.string,
    statement_descriptor: PropTypes.string
  }

  static defaultProps = {
    amount: 0,
    interval: null,
    statement_descriptor: ''
  }

  render() {
    return (
        <div className={styles.invoiceItem} title={this.props.statement_descriptor}>
          <div className={styles.name}>
            {this.props.statement_descriptor}
          </div>
          <div className={styles.gap}></div>
          <div className={styles.amount}>
            {usd(this.props.amount)}
            <span className={styles.interval}>{this.props.interval ? ` / ${this.props.interval}` : null}</span>
          </div>
        </div>
    );
  }
}

export default InvoiceItem;
