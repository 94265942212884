
const API_ROOT = process.env.REACT_APP_API_ROOT;

export default class API {
  
  // Fetch a user's account information based on obfuscated code
  static getAccount(id) {
    return fetch(`${API_ROOT}/device/${id}`)
            .then(res => res.json())
            .catch(err => err.json());
  }

  // Generate a stripe customer id for first-time customers
  static sendToken(id, data) {
    return fetch(`${API_ROOT}/device/${id}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .catch(err => err.json());
  }
}