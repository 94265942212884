import React, { Component } from 'react';

import styles from './view-successful-activation.module.scss';

class ViewSuccessfulActivation extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.title}>
          Your payment was successfully processed. Thank you for supporting RainedOut.
        </div>
      </React.Fragment>
    );
  }
}
  
export default ViewSuccessfulActivation;
  