import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import api from 'data/api';

import Divider from 'components/divider/divider.component';
import Loader from 'components/loader/loader.component';

import styles from './payment-request-form.module.scss';
import 'styles/forms.scss';

class PaymentRequestForm extends React.Component {

  static propTypes = {
    id: PropTypes.string,
    onError: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    paymentCountry: PropTypes.string,
    paymentCurrency: PropTypes.string,
    paymentLabel: PropTypes.string,
    paymentAmount: PropTypes.number
  }

  static defaultProps = {
    id: '',
    paymentCountry: 'US',
    paymentCurrency: 'usd',
    paymentAmount: 0,
    // Stripe throws an error if this field is blank, "Empty total label may be confusing the user"
    // An extra space tricks their validation.  We need not worry about passing an empty label
    // as the field is populated eventually.
    paymentLabel: ' '
  }

  constructor(props) {
    super(props);

    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = props.stripe.paymentRequest({
      country: this.props.paymentCountry,
      currency: this.props.paymentCurrency,
      total: {
        label: this.props.paymentLabel,
        amount: this.props.paymentAmount,
      },
    });

    // Send token retreived from one-click pay button to API
    paymentRequest.on('token', async ({complete, token, ...data}) => {
      const id = this.props.id;
      try {
        await api.sendToken(id, { token: token.id });
        complete('success');
        this.props.onSuccess();
      } catch (err) {
        complete('failure');
        this.props.onError();
      }
    });

    // Check for one-click checkout support
    paymentRequest.canMakePayment().then((result) => {
      if (!this.unmounted) {
        this.setState({ canMakePayment: !!result });
      }
    });

    this.state = {
      canMakePayment: false,
      paymentRequest,
      loading: false
    };
  }

  componentWillUnmount () {
    this.unmounted = true;
  }

  unmounted = false;

  // Send token retreived from card checkout to API
  async sendToken(id, token) {
    try {
      const res = await api.sendToken(id, { token });
      switch (res.status_code) {
        case 200:
          this.props.onSuccess();
          break;
        case 500:
          this.props.onError();
          break;
        default:
          break;
      }
    } catch (err) {
      this.props.onError();
    }

    this.setState({ loading: false });
  }
  

  // Retrieve tokenized card information then provide our API with response token.
  onSubmit = e => {
    e.preventDefault();

    this.setState({ loading: true });

    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then(res => {
          if (res.token) {
            this.sendToken(this.props.id, res.token.id);
          } else {
            this.setState({ loading: false });
          }
        });
    } else {
      this.setState({ loading: false });
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  // The resulting iframed credit card element can be styled with javascript
  // See https://github.com/stripe/react-stripe-elements#demo
  createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#1f336f',
          letterSpacing: '0.025em',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          '::placeholder': {
            color: 'rgba(31, 51, 111, 0.32)',
          },
          padding,
        },
        invalid: {
          color: '#fc2234',
        },
      },
    };
  };

  renderCard() {
    return (
      <React.Fragment>
        <Divider>
          Pay with Debit / Credit
        </Divider>
        <form className={styles.card} onSubmit={this.onSubmit}>
          <CardElement {...this.createOptions(this.props.fontSize)} />
          <button className={styles.submitButton}>
          { this.state.loading ?
          <Loader in={this.state.loading} transitionIn={true} /> : 
          'Charge Card' }
          </button>
        </form>
      </React.Fragment>
    );
  }

  renderRequestButton() {
    return this.state.canMakePayment ? (
      <React.Fragment>
        <Divider>
          One-click payment
        </Divider>
        <PaymentRequestButtonElement
          paymentRequest={this.state.paymentRequest}
          className={styles.paymentRequestButton}
          style={{
            // For more details on how to style the Payment Request Button, see:
            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
            paymentRequestButton: {
              theme: 'dark',
              height: '64px',
            },
          }}
        />
      </React.Fragment>
    ) : null;
  }

  render() {
    return (
      <div>
        {this.renderCard()}
        {this.renderRequestButton()}
      </div>
    );
  }
}

// 'injectStripe' must be used in the child element of the 'Elements' component
// https://github.com/stripe/react-stripe-elements#setting-up-your-payment-form-injectstripe
export default injectStripe(PaymentRequestForm);
