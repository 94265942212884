import React, { Component } from 'react';
import PropTypes from 'prop-types'

import InvoiceItem from 'components/invoice-item/invoice-item.component';

import styles from './invoice-items.module.scss';

class InvoiceItems extends Component {

  static propTypes = {
    items: PropTypes.array.isRequired
  }

  render() {
    return (
      <div className={styles.invoiceItems}>
        {this.props.items.map((item, index) =>
          <InvoiceItem
            key={index}
            amount={item.amount}
            interval={item.interval}
            statement_descriptor={item.statement_descriptor}
          />
        )}
      </div>
    );
  }
}

export default InvoiceItems;
