import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import styles from './view-failed-activation.module.scss';

class ViewFailedActivation extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.title}>
          Charge Declined
        </div>
        <div className={styles.blurb}>
          Please verify your payment information and try again.
          <Link to="/">Try Again</Link>
        </div>
      </React.Fragment>
    );
  }
}
  
export default ViewFailedActivation;
  