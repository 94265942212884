import React, { Component } from 'react';

import styles from './view-activated.module.scss';

class ViewActivated extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.title}>
          Your device is already active!
        </div>
      </React.Fragment>
    );
  }
}
  
export default ViewActivated;
  