import React, { Component } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';

import styles from './divider.module.scss';

class Divider extends Component {

  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: null
  }
  render() {
    return (
        <div className={classNames([styles.divider], {
          [this.props.className]: this.props.className
        })}>
          <span className={styles.dividerText}>
            {this.props.children}
          </span>
        </div>
    );
  }
}

export default Divider;