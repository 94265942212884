import React, { Component } from 'react';

import styles from './view-privacy.module.scss';

class ViewPrivacy extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles.title}>
          Privacy
        </div>
        <div className={styles.blurb}>
        <p>This statement applies to websites operated by or on behalf of RainedOut, RainedOut, LLC and its affiliated companies and subsidiaries.</p>

        <p>We value the trust you place in RainedOut, and maintain strict privacy policies. We are committed to providing our customers and users with a secure environment and state of the art technologies to safeguard your information. In the event that you terminate your customer relationship with us, or become an inactive customer, RainedOut will continue to adhere to the policies and practices described in this notice.</p>

        <h2 className={styles.header}>Information We Collect About You</h2>
        <p>When you visit our web site, and as your trusted notification utility, we collect, retain and use non-public personal information about our potential, current and former customers. We are likely to record two kinds of information:</p>

        <ul>
        <li>Non-personal statistical information collected in aggregate. This is anonymous information on subject such as: how many visitors come to our web site, how they navigate through the site, which browsers they use to visit the site, etc.</li>
        <li>Personal information that you knowingly supply to us. When you sign up for our products and services or register on our web site to access our product demo or to access your account information, RainedOut will collect personal information that will help us to identify you, authenticate you, and provide you with better service. This information is called "personally identifiable information" and is used to associate usage information with a specific individual or entity. Examples of personally identifiable information are: customer name, address, telephone number, email address, account number, etc.</li>
        </ul>

        <h2 className={styles.header}>What We Do With Your Information</h2>
        <p>RainedOut’s communication services enable millions of our customers to communicate with each other quickly and efficiently and this base level of service is provided free. We are able to do this as advertisers and sponsors provide the funding needed to keep RainedOut going. As such, from time to time, RainedOut will work with companies that wish to advertise their products to you. In these cases, we may disclose information to unaffiliated third parties or we may allow these companies to sponsor advertisements or sections of our site and or apps.</p>

        <p>We will also disclose personally identifiable information to government officials and law enforcement agencies when it is required by law (e.g., in compliance with a subpoena or court order) or if we believe in good faith that such action is appropriate and necessary to protect and defend the rights of RainedOut, or to protect the safety of RainedOut users and the general public. Because of the uncertain regulatory environment associated with privacy issues, we may also be forced to disclose personally identifiable and other information to the government.</p>

        <h2 className={styles.header}>We Protect Your Information</h2>
        <p>The protection of your personally identifiable information is of utmost importance to us. We maintain strict practices and procedures to safeguard your privacy in accordance with this privacy policy. We restrict employee access to customer information to only those who have a business reason to know such information, and we educate our employees about the importance of confidentiality and customer privacy.</p>

        <h2 className={styles.header}>Security</h2>
        <p>It is possible that Electronic Communications may be accessed by unauthorized third parties when communicated between you and RainedOut using the Internet, other network communications facilities, telephone or any other electronic means. All transfer of personal information on RainedOut.com and RainedOut.net is secured by encrypted user names and passwords, and the industry standard Secure Sockets Layer (SSL) protocol. SSL prohibits other web users from gaining access to your personal information, and to help protect the loss, misuse, and alteration of your information.</p>

        <h2 className={styles.header}>Policy Towards Children</h2>
        <p>Our Services are not directed to people under 13. If you become aware that your child has provided us with personal information without your consent, please contact us at <a href="mailto:contact@RainedOut.com" className={styles.link}>contact@RainedOut.com</a>. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we take steps to remove such information and terminate the child's account.</p>

        <h2 className={styles.header}>Changes to Privacy Policy</h2>
        <p>We reserve the right to change or update this Privacy Policy without notice.</p>

        <h2 className={styles.header}>Linked Web Sites</h2>
        <p>RainedOut web site contains links to various other web sites. Please be aware that these other sites may have privacy policies that differ from RainedOut's. RainedOut is in no way responsible for either the content that appears on linked sites, or your privacy once you have linked to other sites.</p>

        <h2 className={styles.header}>Opt Out</h2>
        <p>If you wish to not participate in RainedOut services, please cancel your account if you are an administrator or if you are a subscriber, text STOP in reply to any message that you receive and we will remove your information from our systems. If you wish to opt-out of any unaffiliated 3rd party marketing, then please send an email to <a href="mailto:optout3rdparty@rainedout.com" className={styles.link}>optout3rdparty@rainedout.com</a>.</p>

        <h2 className={styles.header}>Additional Information</h2>
        <p>Questions regarding this Privacy Policy or the security practices of RainedOut should be directed to RainedOut Privacy and Security Administrator by using our Contact page.</p>

        <p>This Privacy Policy is effective as of September 25, 2017.</p>
        </div>
      </React.Fragment>
    );
  }
}
  
export default ViewPrivacy;
  