export function usd (value) {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  
  // Stripe feeds values back in pennies — convert to dollars
  return currency.format(value / 100);
}
