import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react'
import { toast } from 'react-toastify';
import api from 'data/api';

import Loader from 'components/loader/loader.component';

import styles from './wrapper-for-view.module.scss';
import 'styles/transitions.scss';

class WrapperForView extends Component {

  static propTypes = {
    // We restrict the children of this element ot a single child due ot the method used for
    // passing account data down to the views.
    children: PropTypes.element.isRequired
  }
  
  componentDidMount() {
    // If an account id is present in the URL, fetch its respective data
    // so that it can be passed down to its wrapped child view component.
    if (this.props.match.params.id) {
      this.getAccount(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }

    // If an account id is present in a newly transitioned-to URL, fetch its
    // respective data. e.g. User loads the app on the terms or privacy page 
    // and then navigates back to a valid account id.
    if (!prevProps.match.params.id && this.props.match.params.id) {
      this.getAccount(this.props.match.params.id);
    }
  }

  async getAccount(id) {
    const {
      setLoading
    } = this.props.accountStore;

    try {
      setLoading(true);

      // Fetch account information based on id
      const res = await api.getAccount(id);

      switch (res.status_code) {
        case 200:
          this.onValidAccount(res);
          break;
        case 400:
        case 500:
          this.onInvalidAccount();
          break;
        default:
          break;
      }
    } catch (err) {
      setLoading(false);
    }
  }

  // On successful account retrieval, redirect them to their respective state in the payment flow.
  onValidAccount(res) {
    const {
      setId,
      setActive,
      setLoading,
      setName,
      setEmail,
      setPlan,
      setIsStripeCustomer
    } = this.props.accountStore;

    const {
      active,
      email,
      name,
      plan,
      stripe_customer_id
    } = res;
    const isStripeCustomer = stripe_customer_id !== null;

    setId(this.props.match.params.id);
    setActive(active);
    setName(name);
    setEmail(email);
    setPlan(plan);
    setIsStripeCustomer(isStripeCustomer);

    // No-need to re-activate.  Go to a happy place.
    if (active) {
      this.props.history.replace(`/activated/${this.props.match.params.id}`);
    }
    // If the user is not yet a stripe customer, push them to the account creation page.
    else if (!isStripeCustomer) {
      this.props.history.push(`/id/${this.props.match.params.id}`);
    }
    
    setLoading(false);
  }

  // Route the user to the placeholder page and inform them the device id is invalid
  onInvalidAccount() {
    const {
      setLoading
    } = this.props.accountStore;

    this.props.history.push(`/`);
    toast.error('Sorry, that device id is invalid.')

    setLoading(false);
  }

  render() {
    const { loading } = this.props.accountStore;
    return (
        <React.Fragment>
          <div className={styles.view}>
            <div className={styles.topStripe}></div>

              {/* Pass wrapping response props to nested views */}
              {loading ? 
                <div className={styles.loader}>
                  <Loader in={loading} transitionIn={true} />
                </div> :
                this.props.children
              }

          </div>
          <div className={styles.footer}>
          <span className={styles.footerItem}>© {new Date().getFullYear()}, RainedOut, LLC. All Rights Reserved.</span>
            <Link to="/terms" className={styles.footerLink}>Terms and Conditions</Link>
            <Link to="/privacy" className={styles.footerLink}>Privacy Policy</Link>
          </div>
        </React.Fragment>
    );
  }
}

export default withRouter(inject('accountStore')(observer(WrapperForView)));
